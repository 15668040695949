html {
  font-size: 10px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: rgb(244, 244, 244);
  color: #333;
  font-family: sans-serif;
}

p {
  font-size: 1.6rem;
}

small {
  font-size: 1.2rem;
}

.container {
  margin: 0 auto;
  max-width: 1000px;
  padding: 5px 40px 40px 40px;
}

.title {
  font-family: sans-serif;
  font-size: 4.4rem;
  text-align: center;
  margin-bottom: 2rem;
}

.form {
  display: grid;
  place-items: center;
}

.label {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.9rem;
  text-transform: uppercase;
}

.input {
  font-size: 1.6rem;
  padding: 0.5rem 2rem;
  line-height: 2.8rem;
  border-radius: 20px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  width: 90%;
}

.button {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 1rem 2rem;
  border: 1px solid rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 250ms;
  width: 50%;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.85);
}

@media (min-width: 786px) {
  .form {
    grid-template-columns: auto 1fr auto;
    grid-gap: 1rem;
    align-items: center;
  }

  .input {
    margin-bottom: 0;
    width: 95%;
  }
  .button {
    width: 100%;
  }
  .label {
    margin-bottom: 0;
  }
  .label::after {
    content: " :";
  }
}

.card-list {
  margin-top: 4rem;
}

.card {
  padding: 2rem 4rem;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  margin-bottom: 2rem;
  background-color: white;
}

.card--title {
  margin-bottom: 0.5rem;
  font-size: 3.2rem;
}

.card--image {
  margin: 0 auto;
  display: block;
}

@media (min-width: 600px) {
  .card {
    display: flex;
    flex-direction: row !important;
  }
  .card--content {
    margin-left: 3em;
  }
  .card--image {
    margin: 0px !important;
  }
}
